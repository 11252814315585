.InstructionWindow_container {
  /* position: absolute; */
  /* inset: 10rem; */
  width: 18rem;
  min-width: 18rem;
  /* height: 24rem; */
  background-color: rgba(255, 255, 255, 0.9);
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.80) 80%, rgba(255, 255, 255, 0.80) 100%); */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  /* text-align: center; */
  padding: 0.5rem;
  margin: 0 1rem;
  border: 1px solid #dc3975b5;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
}
.InstructionWindow_LevelWrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.InstructionWindow_text {
  font-size: 1.0rem;
}
.InstructionWindow_levelTitle{
align-self: center;
}

.InstructionWindow_InstructionText {
  font-size: 0.8rem;
  align-self: flex-start;
  margin-bottom: 1.0rem;
  /* margin-left: 2rem; */
}

.InstructionWindow_BrandText {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 98.82%, #FFB800 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  align-self: center;
  font-size: 1rem;
  margin-bottom: 0.2rem;
}