#arrowAnim {
  /* width: 100vw;
  height: 100vh; */
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  position: absolute;
  /* background-color: red; */
  height: 2rem;
  /* transform: rotate(225deg); */
  /* transform:translate3d(250px, 250px, 0) rotate(225deg); */
  /* transform-origin: top left; */
}

.arrow {
  width: 0.8rem;
  height: 0.8rem;
  border: 0.5rem solid;
  /* border-color: black transparent transparent black; */
  border-color: #DC3974 transparent transparent #DC3974;
  /* transform: rotate(-45deg); */
  transform: rotate(135deg);
}

.arrowSliding {
  /* position: absolute; */
  /* -webkit-animation: slide 4s linear infinite;  */
  animation: slide 2s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 1s; 
    animation-delay: 1s;
}
.delay2 {
  -webkit-animation-delay: 2s; 
    animation-delay: 2s;
}
.delay3 {
  -webkit-animation-delay: 3s; 
    animation-delay: 3s;
}

/* @-webkit-keyframes slide {
    0% { opacity:0; transform: translateX(15vw); }	
   20% { opacity:1; transform: translateX(9vw); }	
   80% { opacity:1; transform: translateX(-9vw); }	
  100% { opacity:0; transform: translateX(-15vw); }	
} */
/* @keyframes slide {
    0% { opacity:0; transform: translateX(-15vw); }	
   20% { opacity:1; transform: translateX(-9vw); }	
   80% { opacity:1; transform: translateX(9vw); }	
  100% { opacity:0; transform: translateX(15vw); }	
} */
@keyframes slide {
  0% { opacity:1; transform: translateX(10%); }	
 100% { opacity:1; transform: translateX(80%); }		
}
