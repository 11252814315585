.menu-container {
  z-index: 2;
}

.menu {
  display: flex;
  flex-direction: column;
  background-color: rgba(238, 238, 238, 1.0);
  padding: 0.1rem 0.4rem;
  width: 12rem;
  border: solid 1px rgb(198, 198, 198);

}

.menuItem:hover {
  background-color: rgb(211, 211, 211);
}

.menuItem {
  padding: 0.2rem 1rem;
}

.menuLine {
  min-height: 1px;
  width: 100%;
  background: rgba(145, 145, 145, 1.0);
}
.context_animationText{
  /* background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 98.82%, #FFB800 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #DC3974;
  border: 3px dotted #DC3974;
}

.contextMenuAnimate {
  animation: contextMenuAnimation 5000ms infinite;
}

.contextMenuHelpText {
  color: rgb(252, 0, 0);
  font-size: 0.7rem;
}

.notAvailableOption {
  color: rgb(198, 198, 198)
}

.open {
  font-weight: bold;
}

.contextMenuAddFolderDiv {
  padding: 0.2rem 0.2rem;
  display: flex;
  justify-content: flex-start;
}

.contextMenuAddFolderPicture {
  width: 1.0rem;
  height: 1.0rem;
  margin-right: 0.2rem;
  /* margin: 0 0.4rem; */
}

.subMenuItem {
  padding: 0.2rem 1.4rem;
}

.subMenuItem:hover {
  background-color: rgb(211, 211, 211);
}

.contextMenuAddFolderDiv:hover {
  background-color: rgb(211, 211, 211);
}


@keyframes contextMenuAnimation {
  0% {
    background-color: rgba(169, 0, 0, 0.473);
  }

  50% {
    background-color: rgba(255, 255, 255, 0.473);
  }

  100% {
    background-color: rgba(169, 0, 0, 0.473);
  }
}