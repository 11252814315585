.ContactPage_background {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
}
.ContactPage_en {
  background: url(../../images/screenshot_en.jpg);
  background-size: cover;
  background-position: center;
}
.ContactPage_fr {
  background: url(../../images/screenshot_fr.jpg);
  background-size: cover;
  background-position: center;
}
.ContactPage_overlay{
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 3;
}

a {
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.7);
}

a:visited {
  color: rgba(0, 0, 0, 0.7);
}

/* Classes */
.ContactPage_front {
  width: 30rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 1rem;
  border: 1px solid #dc3975b5;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  z-index: 4;
  position: relative;
}

.ContactPage_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.ContactPage_BrandLink{
  align-self: center;
}
.ContactPage_BrandText {
  font-family: 'Ubuntu';
  font-weight: 700;
  background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 98.82%, #FFB800 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  align-self: center;
  font-size: 1rem;
  cursor: pointer;
}

.ContactPage_InstructionText {
  font-size: 1rem;
  align-self: flex-start;
  margin-bottom: 0.5rem;
  margin-left: 2rem;
}

.ContactPage_form {
  display: flex;
  margin-bottom: 0.5rem;
  margin-left: 2rem;
}

.ContactPage_button {
  margin: 0;
  border-radius: 0;
}

.ContactPage_input{
  text-decoration: none;
  margin: 0;
  padding: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.50);
  border-top: 1px solid rgba(0, 0, 0, 0.50);
  border-bottom: 1px solid rgba(0, 0, 0, 0.50);
  border-right: none;
  width: 15rem;
}

.ContactPage_modalButton {
  padding: 0.3rem;
  background-color: white;
  border-radius: 5px;
  padding: 0.5rem 1.2rem;
  margin: 1rem;
  border: 1px solid #dc3975b5;
  cursor: pointer;
}
.ContactPage_modalButton:active{
  background-color: #ffb700b3;
}
.ContactPage_modalButton_small {
  padding: 0.3rem;
  font-size: 0.7rem;
}
.ContactPage_contactModal_button {
  margin: 0;
  border-radius: 0;
}
.ContactPage_buttonText {
  background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 98.82%, #FFB800 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  cursor: pointer;
}
.ContactPage_copyright {
  align-self: center;
  font-size: 0.6rem;
}
.ContactPage_closeButton{
  border-radius: 3px;
  padding: 0.2rem 0.4rem;
  margin: 0.1rem;
  cursor: pointer;
  background-color: #DC3974;
  opacity: 0.9;
  color: white;
  font-weight: 700;
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
  z-index: 5;
  opacity: 0.9;
}

.ContactPage_h1{
  font-size: 1.5rem;
}
.ContactPage_email{
  align-self: center;
}
.ContactPage_legal{
  align-self: flex-start;
  font-size: 0.9rem;
  margin: 0 0 1rem 2rem;
  cursor: pointer;
}


