.LandingDesktop_background {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
	position: relative;
}

.LandingDesktop_en {
	background: url(../../images/screenshot_en.jpg);
	background-size: cover;
	background-position: center;
}

.LandingDesktop_fr {
	background: url(../../images/screenshot_fr.jpg);
	background-size: cover;
	background-position: center;
}

.LandingDesktop_overlay {
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	z-index: 3;
}

a {
	text-decoration: none;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.7);
}

a:visited {
	color: rgba(0, 0, 0, 0.7);
}

/* Classes */
.LandingDesktop_front {
	width: 30rem;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 1rem 1rem;
	border: 1px solid #dc3975b5;
	font-family: 'Roboto', sans-serif;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.7);
	z-index: 4;
}

.LandingDesktop_Text {
	font-size: 1.2rem;
}

.LandingDesktop_TextDynamic {
	font-size: 1rem;
	align-self: flex-start;
	margin-left: 4.1rem;
}

.LandingDesktop_gradientText {
	color: rgba(0, 0, 0, 0.8);
	background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 98.82%, #FFB800 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 1rem;
}

.LandingDesktop_ButtonAlternate {
	padding: 0.3rem;
	background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 88.82%, #FFB800 100%);
	border-radius: 5px;
	padding: 0.5rem 1.2rem;
	margin: 1rem;
	cursor: pointer;
}

.LandingDesktop_ButtonAlternate:hover {
	background: linear-gradient(92.73deg, #DC3974 32.67%, #FF5354 60.82%, #FFB800 100%);
}

.LandingDesktop_buttonTextAlternate {
	color: rgba(255, 255, 255, 0.9);
	font-weight: 500;
	cursor: pointer;
}

.LandingDesktop_Button {
	padding: 0.3rem;
	background-color: white;
	border-radius: 5px;
	padding: 0.5rem 1.2rem;
	margin: 0.5rem 0 1rem 0;
	border: 1px solid #dc3975b5;
	cursor: pointer;
}

.LandingDesktop_Button:active {
	background-color: #ffb700b3;
}

.LandingDesktop_Button_small {
	padding: 0.3rem;
	font-size: 0.9rem;
	margin: 0 0.3rem 0.5rem 0.3rem;
}

.LandingDesktop_buttonText {
	background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 98.82%, #FFB800 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 500;
	cursor: pointer;
}

.LandingDesktop_ButtonContainer {
	display: flex;
}

.LandingDesktop_copyright {
	align-self: center;
	font-size: 0.6rem;
}

.LandingDesktop_languageContainer {
	display: flex;
}

.LandingDesktop_language_Button {
	width: 1.2rem;
	margin: 0.5rem 0.5rem 0 0.5rem;
	cursor: pointer;
}