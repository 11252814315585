.WindowSideBarNavLinkBox {
  display: flex;
  /* margin: 0.2rem 0; */
  padding: 0.2rem 0.0rem 0.2rem 0.5rem;
  align-items: center;
}
.WindowSideBarNavLinkBoxHover:hover{
  background-color: rgb(229, 243, 254);
}
.WindowSideBarNavLinkBoxIsTargeted{
  background-color: rgb(229, 243, 254);
}
.WindowSideBarNavLinkBoxSelected {
  background-color: rgb(205, 232, 254);
}
.WindowSideBarNavLinkBoxSelected:hover{
  outline: solid 1px rgb(155, 210, 253);
}

.WindowSideBarIcon {
  width: 1rem;
  height: 1rem;
  margin: 0 0.1rem;
}

.WindowSideBarFolderText {
  margin: 0 0.1rem;
  cursor: default;
  /* to avoid text selection when double click: */
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}
.sideFolderHighlight{
  border: 3px dotted #DC3974;
}