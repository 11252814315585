.folder {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* cursor: move; */
}

.folderPicture {
  height: 3rem;
  width: 3rem;
}

.filePicture {
  height: 2.5rem;
  width: 2.5rem;
  margin-bottom: 0.3rem;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2),0 1px 4px 0 rgba(0,0,0,0.19);
}

.folderTitle {
  /* padding-left: 0.6rem; */
  cursor: default;
}

.inputBox {
  width: 5.6rem;
  font-size: 0.7rem;
  border: 1px solid black;
  outline: none;
}

/* .selected{
  background-color: rgba(123, 204, 255, 0.331);
} */
.folder:hover {
  background-color: rgb(229, 243, 254);
}
.folderIsTargeted {
  background-color: rgb(229, 243, 254);
}

.selected {
  background-color: rgb(205, 232, 254);
  outline: solid 1px rgb(155, 210, 253);
}

.selected:hover {
  background-color: rgb(205, 232, 254);
  outline: solid 1px rgb(155, 210, 253);
}
.folderHighlight{
  border: 3px dotted #DC3974;
}
.folderHighlight2{
  border: 3px dotted #DC3974;
}