.legal {
  font-family: 'Roboto', sans-serif;
  padding: 2rem;
  border: 2px solid #DC3974;
  border-radius: 20px;
  min-width: 360px;
}

.legal div {
  margin: 1rem 0.2rem;
}

.legal_gradientText {
  color: rgba(0, 0, 0, 0.8);
  background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 98.82%, #FFB800 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
  text-align: center;
}

.legal_clickable {
  cursor: pointer
}