.App {
  margin: 0;
  height: 100vh;
  width: 100vw;
  min-width: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(0, 0, 29); */
  color: black;
  font-size: 0.7rem;
  background: url(../images/background2.jpg);
  background-size: cover;
  background-position: center;
}
.instructionAndWindowWrapper{
  width: 100%;
  display: flex;
  margin-top: auto;
  margin-bottom:auto ;
  justify-content: flex-start;
}
a {
  text-decoration: none;
  margin: 0;
  padding: 0;
}

@media (prefers-reduced-motion: no-preference) {
}