.WindowBodyContainer {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: white;
}

/* .windowBody_animationContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 60.82%, #FFB800 100%);
  animation: windowBodyAnimation 5000ms infinite;
} */

.windowBody_animationText {
  position: absolute;
  color: red;
  font-weight: bold;
  background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 98.82%, #FFB800 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 8rem;
  text-align: center;
  margin: 0 0 5rem 0;
}

.windowBody_zoneHighLight {
  position: absolute;
  width: 98%;
  height: 98%;
  /* width: 5rem;
  height: 5rem; */
  /* margin: 4rem 0 0 0; */
  /* background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 60.82%, #FFB800 100%); */
  border: 3px dotted #DC3974;
  animation: windowBodyAnimation 2000ms infinite;
}
.windowBody_FolderToFolderArrayContainer {
  position: absolute;
  width: 98%;
  height: 98%;
}

.WindowBodyDragZone {
  width: 95%;
  height: 95%;
  z-index: 1;
}

@keyframes windowBodyAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* .DropZone{
  width: 20rem;
  height: 10rem;
  background-color: aquamarine;
} */
.selection-area {
  background: rgba(46, 115, 252, 0.11);
  border: 2px solid rgba(98, 155, 255, 0.81);
  border-radius: 0.1em;
}

.container {
  width: 100%;
  height: 100%;
}