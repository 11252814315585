

/* Classes */
.StatusBar_front {
  /* width: 30rem; */
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* padding: 1rem 1rem; */
  border-bottom: 1px solid #dc3975b5;
  border-left: 1px solid #dc3975b5;
  border-right: 1px solid #dc3975b5;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  position:absolute;
  top:0;
  right:0;
}
.StatusBar_div {
  /* width: 30rem; */
  /* background-color: rgba(255, 255, 255, 0.9); */
  /* border-radius: 0 0 5px 5px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0rem 0rem;
  margin-left: auto;
  /* border-bottom: 1px solid #dc3975b5;
  border-left: 1px solid #dc3975b5;
  border-right: 1px solid #dc3975b5; */
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  /* z-index: 3; */
  /* position:absolute; */
  /* top:0;
  right:0; */
}

.StatusBar_GradientRight {
  background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 88.82%, #FFB800 100%);
  opacity: 0.9;
}
.StatusBar_GradientLeft {
  background: linear-gradient(92.73deg, #FFB800 0%, #FF5354 12.67%, #DC3974 100%);
  opacity: 0.9;
}
.StatusBar_Logo{
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin: 0.3rem 0.3rem 0.3rem 1rem;
  cursor: pointer;
  background-color: #DC3974;
  opacity: 0.9;
  color: white;
  font-weight: 700;
  font-size: 0.9rem;
}

.StatusBar_ButtonAlternate {
  border-radius: 5px;
  padding: 0.5rem 0rem;
  margin: 0.3rem;
  cursor: pointer;
  width: 2.5rem;
}
.StatusBar_login {
  padding: 0.5rem 0.5rem;
  border-radius: 5px;
  margin: 0.3rem;
  cursor: pointer;
}

.StatusBar_email{
  font-size: 0.9rem;
  font-weight: 700;
}

.StatusBar_GradientRight:hover {
  background: linear-gradient(92.73deg, #DC3974 32.67%, #FF5354 60.82%, #FFB800 100%);
}
.StatusBar_GradientLeft:hover {
  background: linear-gradient(92.73deg, #FFB800 10%, #FF5354 30.67%, #DC3974 100%);
}

.StatusBar_buttonTextAlternate {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 700;
  cursor: pointer;
}
.StatusBar_shutdownButton{
  width: 1.5rem;
  padding: 0.6rem 0.5rem;
  margin: 0.3rem 0.3rem 0.3rem 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.StatusBar_helpButton{
  width: 1.5rem;
  padding: 0.5rem 0.5rem;
  margin: 0.3rem 0.3rem 0.3rem 0.3rem;
}
.StatusBar_loginButton{
  padding: 0.5rem 0.5rem;
  margin: 0.3rem 0.3rem 0.3rem 0.3rem;
}
.StatusBar_shutdownSVG{
  width: 0.9rem;
}
