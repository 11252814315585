.navBar {
  display: flex;
  margin: 0.2rem 0;
  max-height: 1.5rem;
}

.navControlBox {
  display: flex;
  align-items: center;
}

.navBarArrow {
  margin: 0 0.2rem;
  padding: 0 0.1rem;
  width: 0.9rem;
}
.navBarArrowHover:hover{
  filter: invert(47%) sepia(97%) saturate(1720%) hue-rotate(181deg) brightness(103%) contrast(97%);
}

.navBarArrowUp:hover{
  background-color: rgb(205, 232, 254);
  outline: solid 1px rgb(155, 210, 253);
}
.navBarChevron {
  margin: 0 0.2rem;
  width: 0.3rem;
}


.navBarPathField {
  background-color: white;
  border: 1px solid rgb(203, 203, 203);
  /* padding: 0.2rem; */
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.navBarPathFolderImage {
  width: 1rem;
  height: 1rem;
}
.navBarPathInitialFolderWrapper{
  display: flex;
  align-items: center;
}
.navBarPathFolderWrapper{
  display: flex;
  align-items: center;
}

.navBarPathFolderWrapper:hover {
  background-color: rgb(205, 232, 254);
  outline: solid 1px rgb(155, 210, 253);
}

.navBarPathChevron {
  margin: 0 0.4rem;
  width: 0.4rem;
}

.navBarPathFirstChevron {
  margin: 0 0.4rem 0 0.2rem;
  width: 0.4rem;
  cursor: default;
}

.navBarPathFolder {
  cursor: default;
}

.navBarSearchField {
  background-color: white;
  border: 1px solid rgb(203, 203, 203);
  padding: 0.2rem;
  margin: 0 0.5rem 0 0.5rem;
  width: 9rem;
  min-width: 9rem;
  display: flex;
  align-items: center;
}

.navBarSearchButton {
  width: 0.7rem;
  margin: 0 0.4rem;
}

.navBarSearchText {
  color: rgb(203, 203, 203);
}

.arrowHighlight{
  border: 3px dotted #DC3974;
}

/* .addressBar{
width: 100%;
} */