/* Classes */
.ControlBar_front {
  /* width: 30rem; */
  width: 100%;
  /* max-width: 100%; */
  background-color: rgba(255, 255, 255, 0.7);
  /* border-radius: 0 0 5px 5px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  /* padding: 1rem 1rem; */
  /* border-bottom: 1px solid #dc3975b5; */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.5); */
  /* border-left: 1px solid #dc3975b5;
  border-right: 1px solid #dc3975b5; */
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.7);
  /* z-index: 4; */
}
.ControlBar_frontLanding {
  /* width: 30rem; */
  width: 100%;
  /* max-width: 100%; */
  background-color: rgba(255, 255, 255, 0.7);
  /* border-radius: 0 0 5px 5px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  /* padding: 1rem 1rem; */
  /* border-bottom: 1px solid #dc3975b5; */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.5); */
  /* border-left: 1px solid #dc3975b5;
  border-right: 1px solid #dc3975b5; */
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  position:absolute;
  top:0;
  right:0;
}
.ControlBar_levelDiv{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 16.6rem;
}

.ControlBar_GradientRight {
  background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 88.82%, #FFB800 100%);
  opacity: 0.9;
}
.ControlBar_GradientLeft {
  background: linear-gradient(92.73deg, #FFB800 0%, #FF5354 12.67%, #DC3974 100%);
  opacity: 0.9;
}

.ControlBar_ButtonAlternate {
  border-radius: 5px;
  padding: 0.5rem 0.8rem;
  margin: 0.3rem 0.5rem;
  cursor: pointer;
  width: 4.5rem;
}

.ControlBar_GradientRight:hover {
  background: linear-gradient(92.73deg, #DC3974 32.67%, #FF5354 60.82%, #FFB800 100%);
}
.ControlBar_GradientLeft:hover {
  background: linear-gradient(92.73deg, #FFB800 15%, #FF5354 50.67%, #DC3974 100%);
}

.ControlBar_buttonTextAlternate {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 700;
  cursor: pointer;
}
.ControlBar_level{
  font-size: 0.9rem;
  font-weight: 700;
}

.ControlBar_reloadButton {
  border-radius: 5px;
  background-color: #DC3974;
  opacity: 0.9;
  /* padding: 0.2rem 0.8rem; */
  margin: 0.3rem 0.5rem 0.3rem 0;
  cursor: pointer;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height:2.1rem;
  width:2.2rem;
}
.ControlBar_reloadButton_svg{
  width: 1.3rem;
}