.WindowHeader {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0.2rem 0; 
  border-bottom:1px solid rgb(203, 203, 203) ;
}

.HeaderFileExplorerPicture {
  width: 1.0rem;
  height: 1.0rem;
  margin: 0 0.4rem;
}

.HeaderTitle {
  margin: 0 0.4rem;
}
.HeaderBar {
  color: gray;
}

.HeaderControlBox {
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  margin-right: 0;
}

.HeaderControlButton {
  margin: 0 0.9rem;
  width: 0.6rem;
}