/* body{
  margin: 0;
  padding: 0;
  width: 100vw;
  max-width: 100%;
}
.main{
  width: 100%;
} */
.mobileApp {
  margin: 0;
  padding: 0;
  width: 100vw;
  max-width: 100%;
  /* height: 100vh;
  width: 100vw;
  max-width: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(0, 0, 29); */
  background-color: rgba(255, 255, 255, 0.9);
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.7rem;
  /* background: url(../../images/background2.jpg);
  background-size: cover;
  background-position: center; */
  text-align: center;
}

a {
  text-decoration: none;
  margin: 0;
  padding: 0;
}
.mobileApp_gradientText{
  background: linear-gradient(62.73deg, #DC3974 32.67%, #FF5354 78.82%, #FFB800 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mobileApp_container {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  /* padding: 1rem; */
  /* width: 98%; */
  /* height: 90%; */
  /* background-color: rgba(255, 255, 255, 0.9); */
  /* border: 1px solid #dc3975b5; */
  /* border-radius: 3px; */
  /* padding: 1rem 0; */
}

.mobileApp_Logo {
  /* width: 100vw; */
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-size: 700;
  color: rgba(255, 255, 255, 1.0);
  background: linear-gradient(62.73deg, #DC3974 12.67%, #FF5354 68.82%, #FFB800 80%);
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-weight: bold;
  font-size: 1.2rem;
  align-self: flex-start;
  padding: 0.6rem 0rem 0.6rem 0.6rem;
  text-align: left;
  margin: 0 0 3rem 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 4px 0 rgba(0,0,0,0.09);

}

.mobileApp_text {
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  font-size: 2.5rem;
  font-weight: 700;
  padding: 0 1.3rem;
  margin: 0 0 1rem 0;
  /* height: 7rem; */
}



.mobileApp_intermediateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  /* background-color: #FFB800; */
  /* background: linear-gradient(92.73deg, #DC3974 32.67%, #FF5354 60.82%, #FFB800 100%); */
  /* padding-bottom: 1rem; */
  /* margin-bottom: 0.5rem; */
}

.mobileApp_H2 {
  /* color: rgba(255, 255, 255, 1.0); */
  color: rgba(0, 0, 0, 0.7);
  font-size: 1.8rem;
  /* width: 100%; */
  /* background: linear-gradient(62.73deg, #DC3974 9.67%, #FFB800 60%); */
  /* background-color: #FFB800; */
  margin: 0;
  padding: 1rem 1.3rem;
  text-align: center;
}

.mobileApp_gifDemo {
  width: 90%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.mobileApp_H3 {
  font-size: 1.1rem;
  /* color: rgba(255, 255, 255, 1.0); */
  color: rgba(0, 0, 0, 0.7);
  /* width: 100%; */
  text-align: left;
  /* background: linear-gradient(62.73deg, #DC3974 9.67%, #FFB800 60%); */
  /* background-color: #FFB800; */
  margin: 0;
  padding: 1rem 1.3rem;
  /* border-top: 1px solid rgba(0, 0, 0, 0.7); */
}

.mobileApp_connectText {
  margin: 1.1rem;
  font-size: 0.8rem;

}

.mobileApp_languageContainer{
  /* width: 80%; */
  display: flex;
  /* justify-content: flex-end; */
}

.mobileApp_language_Button{
  width: 1.2rem;
  margin: 0.5rem;
  cursor: pointer;
}