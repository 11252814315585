.Overlay {
  position: fixed;
  inset: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.70) 70%, rgba(0, 0, 0, 0.70) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.Modal {
  /* position: absolute; */
  /* inset: 10rem; */
  width: 30rem;
  /* height: 11rem; */
  background-color: rgba(255, 255, 255, 0.9);
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.80) 80%, rgba(255, 255, 255, 0.80) 100%); */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 1rem;
  border: 1px solid #dc3975b5;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  z-index: 4;
}

.modalText {
  font-size: 1.2rem;
}

.modalTextDynamic {
  font-size: 1rem;
  align-self: flex-start;
  margin-left: 4.1rem;
}

.modalInstructionText {
  font-size: 1rem;
  align-self: flex-start;
  margin-bottom: 0.5rem;
  margin-left: 2rem;
}

.modalUnderline {
  text-decoration: underline;
  font-weight: bold;
}

.gradientText {
  color: rgba(0, 0, 0, 0.8);
  background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 98.82%, #FFB800 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-weight: bold; */
  font-size: 1rem;
}

.modalButtonAlternate {
  padding: 0.3rem;
  /* background: radial-gradient(97.35% 2349.72% at 0% 15.22%, #DC39AE 0%, #FF5354 75%, #FFB800 100%); */
  /* background: linear-gradient(92.73deg, #DC39AE 12.67%, #FF5354 76.99%, #FFB800 100%); */
  background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 88.82%, #FFB800 100%);
  border-radius: 5px;
  padding: 0.5rem 1.2rem;
  margin: 1rem;
  /* border: 1px solid rgb(255, 255, 255); */
  cursor: pointer;
}

.modalButtonAlternate:hover {
  background: linear-gradient(92.73deg, #DC3974 32.67%, #FF5354 60.82%, #FFB800 100%);
}

.buttonTextAlternate {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  cursor: pointer;
}

.modalButton {
  padding: 0.3rem;
  background-color: white;
  border-radius: 5px;
  padding: 0.5rem 1.2rem;
  margin: 1rem;
  border: 1px solid #dc3975b5;
  cursor: pointer;
}
.modalButton:active{
  background-color: #ffb700b3;
}

.buttonText {
  background: linear-gradient(92.73deg, #DC3974 12.67%, #FF5354 98.82%, #FFB800 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  cursor: pointer;
}

.modalButton_small {
  padding: 0.3rem;
  font-size: 0.7rem;
}

