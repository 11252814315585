.WindowSideBar{
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 9rem;
  border-right:1px solid rgb(203, 203, 203) ;
}
.WindowSideBarNav{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: 0.5rem 0 0 0;
  
}