.OneWindow{
  /* margin-top: auto;
  margin-bottom:auto ; */
  margin-left: 0rem;
  height: 70vh;
  width: 60vw;
  min-width: 37rem;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.sideAndBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:row
}