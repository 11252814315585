.WindowsBar {
  /* margin-top:auto; */
  background-color: rgb(213, 221, 236);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2.7rem;
  justify-content: space-between;
}

.barButton {
  width: 1.2rem;
  margin: 0.5rem 0.5rem;
}

.windowsBarStartButton {
  width: 1.5rem;
  /* animation: glowing 2000ms infinite; */
}

.WindowsBarStartBox {
  display: flex;
  align-items: center;
}

.WindowsBarTaskBox {
  display: flex;
  align-items: stretch;
}

.WindowsBarTaskBoxContainer {
  margin: 0.0rem 0.1rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

}

.WindowsBarTaskBoxContainer:last-child {
  background-color: rgba(237, 240, 246, 1.0);
  border-bottom: solid 2px rgba(0, 117, 214, 1.0);
}

.WindowsBarTaskBoxButton {
  width: 1.5rem;
}

.windowsTaskViewButton {
  width: 1.0rem;
}

.windowsExplorerButton {
  width: 1.7rem;

}

.WindowsBarSystemBox {
  display: flex;
  align-items: center;
}

.WindowsBarSystemBoxButton {
  width: 1.0rem;
  margin: 0.3rem 0.2rem;
}
.windowsWifiButton{
  width: 1.5rem;
}

.WindowsBarSystemBoxHourDate {
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items: center;
}

.WindowsBarSystemBoxText {
  margin: 0.2rem 0.2rem;
  font-size: 0.65rem;
}

/* .modalText{
  color: rgb(0, 181, 252);
  font-size: 1rem;
} */

/****** Animation ******/


@keyframes glowing {
  0% {
    background-color: #a80505;
    box-shadow: 0 0 3px #a80505;
    border-radius: 10%;
  }
  50% {
    background-color: #e8198b;
    box-shadow: 0 0 10px #e8198b;
    border-radius: 10%;
  }
  100% {
    background-color: #a80505;
    box-shadow: 0 0 3px #a80505;
    border-radius: 10%;
  }
}